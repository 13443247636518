import React, { useRef , useState ,useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap'

import './cssfile.css'

function NavBar(cat) {
  const [showNab, setNab] = useState(true);
  useEffect(() => {
    if (window.location.pathname.includes("warehouse") || window.location.pathname.includes("sale")) {
      setNab(false);
    } else if(window.location.search.includes('token') && window.location.search.includes('email')) {
      setNab(false);
    } else {
      setNab(true);
    }
  }, []);
  const category = cat['category'];
  const ref = useRef(null);

  const supportLink = (event) => {
    const element = ref.current;
    if (!element.classList.contains('slide-in-support')) {
      element.classList.add('slide-in-support');
      element.style.display = "unset"
    }
    event.stopPropagation();
  }
  const supportLinkDisable = (event) => {
    const element = ref.current;
    if (element.classList.contains('slide-in-support')) {
      element.style.display = "none"
      element.classList.remove('slide-in-support');
    }
    event.stopPropagation();
  }

  return (
    <Navbar key="md" expand="md" className="bg-body-tertiary m-0" variant="dark" role="navigation">
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "0px"
      }}>
        <Navbar.Text id="menuToggle" className="hamburger-menu" onClick={(e) => supportLinkDisable(e)}>
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu" className="menu-list">
            <div className="menu-user-section py-3 pb-4">
              <img src="https://www.beatxp.com/img/beatxp-logo.svg" width="72" alt="beatxp_logo" />
            </div>
              <div className='hamburger-list-scrollable'>
                {category && category.length ? category.map((cat) => {
                  return <Nav.Link key={cat.id} href={`https://www.beatxp.com/` + cat.urlKey + '-' + cat.id + '/'} className='m-0 py-0 text-left'>
                    <li className="border-bottom py-4">
                      {cat.name}
                      <svg id="Next" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect id="Boundary" width="24" height="24" fill="none"></rect><path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF"></path></svg>
                    </li>
                  </Nav.Link>
                }) : ``
                }
                <a href="https://www.beatxp.com/login/" className="my-login" id="my-login-tag">
                  <li className='border-bottom mt-2 mx-3 pb-4 py-3'>
                    Login
                    <svg id="Next" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Boundary" width="24" height="24" fill="none" />
                      <path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF" />
                    </svg>
                  </li>
                </a>

                <a className="" onClick={(e) => supportLink(e)}>
                  <li className="border-bottom py-4 mx-3">
                    Support
                    <svg id="Next" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect id="Boundary" width="24" height="24" fill="none"></rect><path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF"></path></svg>
                  </li>
                </a>
              </div>
            <div id="mobile-support" ref={ref} onClick={(e) => supportLink(e)}>
              <div className="menu-user-section py-3 pb-4">
                <img src="https://www.beatxp.com/img/beatxp-logo.svg" width="72" alt="beatxp_logo" />
              </div>
              <ul className="list-inline">
                <li>
                  <Nav.Link href={`https://www.beatxp.com/` + `help/`}>Help and support
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Boundary" width="24" height="24" fill="none" />
                      <path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF" />
                    </svg>
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href={`https://support.beatxp.com/` + `complain/`}>Raise a complaint
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Boundary" width="24" height="24" fill="none" />
                      <path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF" />
                    </svg>
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href={`https://support.beatxp.com/` + `track-order/`}>Track your Order
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Boundary" width="24" height="24" fill="none" />
                      <path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF" />
                    </svg>
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href={`https://support.beatxp.com/` + `track-complaint/`}>Track your complaint
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Boundary" width="24" height="24" fill="none" />
                      <path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF" />
                    </svg>
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href={`https://www.beatxp.com/` + `page/warranty/`}>Warranty registration
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="Boundary" width="24" height="24" fill="none" />
                      <path id="_Color" data-name=" ↳Color" d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" fill="#FFFFFF" />
                    </svg>
                  </Nav.Link>
                </li>
              </ul>
            </div>



            <div style={{
              backgroundColor: "var(--text-dark)",
              height: "fit-content",
              position: "absolute",
              width: "100%",
              left: "0",
              bottom: "0px"
            }}>
              <a href="https://www.beatxp.com/help/" className="need-help">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.143" height="20" viewBox="0 0 17.143 20">
                    <path id="live_help_FILL0_wght400_GRAD0_opsz24" d="M128.476-865.714a1.149,1.149,0,0,0,.845-.345,1.149,1.149,0,0,0,.345-.845,1.15,1.15,0,0,0-.345-.845,1.149,1.149,0,0,0-.845-.345,1.149,1.149,0,0,0-.845.345,1.15,1.15,0,0,0-.345.845,1.149,1.149,0,0,0,.345.845A1.149,1.149,0,0,0,128.476-865.714Zm-.857-3.667h1.762a5.679,5.679,0,0,1,.036-.69,1.878,1.878,0,0,1,.155-.548,2.362,2.362,0,0,1,.3-.488,6.763,6.763,0,0,1,.512-.56,8.356,8.356,0,0,0,1.179-1.393,2.376,2.376,0,0,0,.345-1.274,2.612,2.612,0,0,0-.857-2.036,3.317,3.317,0,0,0-2.31-.774,3.779,3.779,0,0,0-2.226.643,3.318,3.318,0,0,0-1.274,1.786l1.571.619a1.877,1.877,0,0,1,.667-1.036,1.834,1.834,0,0,1,1.167-.393,1.654,1.654,0,0,1,1.071.345,1.118,1.118,0,0,1,.429.917,1.72,1.72,0,0,1-.262.857,4.3,4.3,0,0,1-.881,1,4.236,4.236,0,0,0-.655.655,3.546,3.546,0,0,0-.417.679,3.061,3.061,0,0,0-.238.75A5.34,5.34,0,0,0,127.619-869.381Zm.952,9.381-2.857-2.857H121.9a1.834,1.834,0,0,1-1.345-.56,1.834,1.834,0,0,1-.56-1.345V-878.1a1.834,1.834,0,0,1,.56-1.345A1.834,1.834,0,0,1,121.9-880h13.333a1.834,1.834,0,0,1,1.345.56,1.834,1.834,0,0,1,.56,1.345v13.333a1.834,1.834,0,0,1-.56,1.345,1.834,1.834,0,0,1-1.345.56h-3.81Zm-6.667-4.762h4.571l2.1,2.1,2.1-2.1h4.571V-878.1H121.9ZM128.571-871.429Z" transform="translate(-120 880)" fill="#fff" />
                  </svg>
                  Need Help?</span>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="27.414" height="17.414" viewBox="0 0 27.414 17.414">
                    <path id="arrow" d="M21,26H44.586l-6.293,6.293,1.414,1.414L48.414,25l-8.707-8.707-1.414,1.414L44.586,24H21Z" transform="translate(-21 -16.293)" fill="#fff" />
                  </svg>
                </span>
              </a>
            </div>
          </ul>
        </Navbar.Text>
        <Navbar.Brand href="https://www.beatxp.com/" className='m-0'>
          <img
            src="https://www.beatxp.com/img/beatxp-logo.svg"
            className="d-inline-block align-top"
            alt="Beat XP logo"
          />
        </Navbar.Brand>

      </div>
       { showNab ? (<Navbar.Text className="category-menu-list">
        <ul className="flex-md-row flex-sm-row flex-xll list-inline m-0">
          {category && category.length ? category.map((cat) => {
            return <Nav.Link key={cat.id} href={`https://www.beatxp.com/` + cat.urlKey + '-' + cat.id + '/'} className='m-0 pl-0' style={{paddingRight: '22px'}}> <li id={cat.urlKey}>{cat.name}</li></Nav.Link>
          }) : ``
          }
          <li className="dropdown py-2" id="support">Support
            <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M2.197 0.218058C1.90611 -0.0726866 1.43476 -0.0726866 1.14379 0.218058C0.853112 0.50895 0.853112 0.980303 1.14379 1.27123L5.39919 5.52659C5.69008 5.81733 6.16143 5.81733 6.45236 5.52659L10.7077 1.27123C10.9985 0.980332 10.9985 0.508981 10.7077 0.218058C10.4168 -0.0726855 9.94548 -0.0726855 9.65455 0.218058L5.9237 3.94892L2.197 0.218058Z" fill="white" />
            </svg>
            <ul className="support-dd">
              <li><a href="https://www.beatxp.com/help/">Help and support</a></li>
              <li><a href="https://support.beatxp.com/complain/">Raise a complaint</a></li>
              <li><a href="https://support.beatxp.com/track-order/">Track your Order</a></li>
              <li><a href="https://support.beatxp.com/track-complaint/">Track your complaint</a></li>
              <li><a href="https://www.beatxp.com/page/warranty/">Warranty registration</a></li>
            </ul>
          </li>
        </ul>
      </Navbar.Text>) : ""}
       { showNab ? (<Navbar.Text>
        <div className="d-flex icons">
          <a href="https://www.beatxp.com/help/" className="help-icon">Help</a>
          <a href="https://www.beatxp.com/cart/" className="position-relative cart-icon-section">
            <img src="https://img.beatxp.com/prod/shopping-cart.svg" alt="Cart" width="24px" height="24px" />
          </a>
          <a href="https://www.beatxp.com/login/" className="user-icon desktopVisible">
            <img loading="lazy" src="https://img.beatxp.com/prod/Account.svg" alt="login" width="24px" height="24px" />
          </a>

        </div>
      </Navbar.Text > ):""}
    </Navbar >
  );
}

export default NavBar;