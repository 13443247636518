import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';

function Form2(props) {
    const [timer, setTimer] = useState(30);
    const [showResend, toggleResend] = useState(false);
    const [updatedMobileNumber, setUpdatedMobileNumber] = useState(props.form1Details.phoneNum);
    let timerSeconds = 30;
    let intervalID = "";
    const otpInputRef = useRef(null);

    useEffect(() => {
        setOTPTimer();
        otpHandler();
        getMobileNumber();
        if (otpInputRef && otpInputRef.current) otpInputRef.current.querySelectorAll('input')[0].focus();
        return () => {
            clearInterval(intervalID);
            timerSeconds = 30;
        }
    }, [])
    
    function getMobileNumber () {
        let mobileSplitArr = props.form1Details.phoneNum.toString().split('');
        mobileSplitArr.splice(4, 0 , '-');
        mobileSplitArr.splice(8, 0 , '-')
        setUpdatedMobileNumber(mobileSplitArr.join(''))
    }

    const getOTP = (event) => {
        if (event.target.value.toString().length !== 6) props.setForm2DetailsHandler({ ...props.form2Details, otp: event.target.value.slice(0, 6) });
        else props.setForm2DetailsHandler({ ...props.form2Details, otp: event.target.value });
    }
    const dispatch = useDispatch();

    const resendOTP = () => {
        dispatch(setIsLoading(true));
        let config = {
            method: 'POST',
            url: process.env.REACT_APP_BUILD_API_URL + 'sendComplainLogin',
            headers: {
                'Content-Type': 'application/json'
            },
            data: { "mobile": props.form1Details.phoneNum }
        };
        Axios(config)
            .then(res => {
                if (res.data.code) {
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: "OTP has been sent to your number", variant: "success" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                } else {
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: res.data.message || "Something went wrong", variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                }
            }).catch(error => {
                console.log("error",error)
                dispatch(setIsLoading(false));
                dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                setTimeout(() => {
                    dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                }, 3000);
            });
        toggleResend(false);
        setOTPTimer();
    }

    const setOTPTimer = () => {
        intervalID = setInterval(() => {
            if (timerSeconds >= 0) {
                setTimer((timerSeconds - 1) > 9 ? (timerSeconds - 1) : "0" + (timerSeconds - 1).toString());
                timerSeconds--;
            } else {
                clearInterval(intervalID);
                timerSeconds = 30;
                toggleResend(true);
                setTimer(30);
            }
        }, 1000);
    }

    const otpHandler = () => {
        // enter OTP in login popup function
        let BACKSPACE_KEY = 8;
        let ENTER_KEY = 13;
        let TAB_KEY = 9;
        let LEFT_KEY = 37;
        let RIGHT_KEY = 39;
        let ZERO_KEY = 48;
        let NINE_KEY = 57;
        function otp() {
            let inputs = otpInputRef.current;
            if (!inputs) return;
            inputs = inputs.querySelectorAll('input')
            // console.log(inputs.querySelectorAll('input'));
            let callback = null;
            function init(completeCallback) {
                callback = completeCallback;
                for (let i = 0; i < inputs.length; i++) {
                    registerEvents(i, inputs[i]);
                }
            }
            function destroy() {
                for (let i = 0; i < inputs.length; i++) {
                    registerEvents(i, inputs[i]);
                }
            }
            function registerEvents(index, element) {
                element.addEventListener("input", function (ev) {
                    onInput(index, ev);
                });
                element.addEventListener("paste", function (ev) {
                    onPaste(index, ev);
                });
                element.addEventListener("keydown", function (ev) {
                    onKeyDown(index, ev);
                });
            }
            function onPaste(index, ev) {
                ev.preventDefault();
                let curIndex = index;
                let clipboardData = ev.clipboardData || window.clipboardData;
                let pastedData = clipboardData.getData("Text");
                for (let i = 0; i < pastedData.length; i++) {
                    if (i < inputs.length) {
                        if (!isDigit(pastedData[i])) break;
                        inputs[curIndex].value = pastedData[i];
                        curIndex++;
                    }
                }
                if (curIndex == inputs.length) {
                    inputs[curIndex - 1].focus();
                    callback(retrieveOTP());
                } else {
                    inputs[curIndex].focus();
                }

            }
            function onKeyDown(index, ev) {
                let key = ev.keyCode || ev.which;
                if (key == LEFT_KEY && index > 0) {
                    ev.preventDefault(); // prevent cursor to move before digit in input
                    inputs[index - 1].focus();
                }
                if (key == RIGHT_KEY && index + 1 < inputs.length) {
                    ev.preventDefault();
                    inputs[index + 1].focus();
                }
                if (key == BACKSPACE_KEY && index > 0) {
                    if (inputs[index].value == "") {
                        // Empty and focus previous input and current input is empty
                        inputs[index - 1].value = "";
                        inputs[index - 1].focus();
                    } else {
                        inputs[index].value = "";
                    }
                }
                if (key == ENTER_KEY) {
                    // force submit if enter is pressed
                    ev.preventDefault();
                    if (isOTPComplete()) {
                        callback(retrieveOTP());
                    }
                }
                if (key == TAB_KEY && index == inputs.length - 1) {
                    // force submit if tab pressed on last input
                    ev.preventDefault();
                    if (isOTPComplete()) {
                        callback(retrieveOTP());
                    }
                }
            }
            function onInput(index, ev) {
                let value = ev.data || ev.target.value;
                let curIndex = index;
                for (let i = 0; i < value.length; i++) {
                    if (i < inputs.length) {
                        if (!isDigit(value[i])) {
                            inputs[curIndex].value = "";
                            break;
                        }
                        inputs[curIndex++].value = value[i];
                        if (curIndex == inputs.length) {
                            if (isOTPComplete()) {
                                callback(retrieveOTP());
                            }
                        } else {
                            inputs[curIndex].focus();
                        }
                    }
                }
            }

            function retrieveOTP() {
                let otp = "";
                for (let i = 0; i < inputs.length; i++) {
                    otp += inputs[i].value;
                }
                props.setForm2DetailsHandler({ otp: otp })
                return otp;
            }
            function isDigit(d) {
                return d >= "0" && d <= "9";
            }
            function isOTPComplete() {
                let isComplete = true;
                let i = 0;
                while (i < inputs.length && isComplete) {
                    if (inputs[i].value == "") {
                        isComplete = false;
                    }
                    i++;
                }
                return isComplete;
            }
            return {
                init: init,
                retrieveOTP: retrieveOTP
            };
        }
        let otpModule = otp("otp-inputs");
        otpModule.init(function (passcode) { });
    }

    return (
        <div>
            <form id="otp-form" onSubmit={(e) => {
                e.preventDefault();
                props.submitForm2Handler(e)

            }}>
                <p>
                    Please enter OTP to verify your complaint
                </p>
                <p id="change-number">
                    A OTP has sent to <span className='font-weight-bold'>{updatedMobileNumber}</span>
                    <span onClick={() => {
                        props.setForm2DetailsHandler((prevState) => ({ ...prevState, otp: "" }));
                        props.setActiveStepHandler({ current: 1, completed: [1] });
                    }} className="cursor-pointer ml-10">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M5.43285 0H3.39555C1.51496 0 0 1.51492 0 3.39555V10.6045C0 12.485 1.51492 14 3.39555 14H10.6045C12.485 14 14 12.4851 14 10.6045V8.56715C14 8.12315 13.6604 7.78353 13.2164 7.78353C12.7724 7.78353 12.4328 8.12314 12.4328 8.56715V10.6045C12.4328 11.6231 11.6231 12.4328 10.6045 12.4328H3.39555C2.3769 12.4328 1.56725 11.6231 1.56725 10.6045V3.39555C1.56725 2.3769 2.3769 1.56725 3.39555 1.56725H5.43285C5.87685 1.56725 6.21647 1.22764 6.21647 0.783623C6.21647 0.339606 5.87705 0.000186463 5.43285 0.000186463V0Z" fill="#FF8300" />
                            <path d="M4.72627 6.42531L3.99493 9.03723C3.8643 9.45518 4.12539 9.87314 4.51727 10.0036C4.64791 10.0296 4.80459 10.0296 4.93522 10.0036L7.54714 9.27225C7.67778 9.2462 7.80841 9.19391 7.88675 9.08933L13.2151 3.73489C14.0247 2.89898 13.9987 1.54092 13.1367 0.731272C12.3271 -0.0523512 11.0211 -0.0523512 10.2114 0.731272L4.90922 6.08571C4.80482 6.16406 4.75253 6.29469 4.72648 6.42532L4.72627 6.42531ZM11.3606 1.85444C11.5695 1.64546 11.9352 1.64546 12.1442 1.85444C12.3532 2.06342 12.3532 2.42906 12.1442 2.63806L7.39048 7.39176L6.60686 6.60814L11.3606 1.85444Z" fill="#FF8300" />
                        </svg>
                    </span>
                </p>
                <div className="did-floating-label-content">
                    {/* <input className="did-floating-input" type="text" placeholder=" "
                        value={props.form2Details.otp}
                        ref={otpRef}
                        onChange={(event) => getOTP(event)} /> */}
                    <fieldset>
                        <div className="d-flex">
                            <div className="input-group d-flex" ref={otpInputRef}>
                                <input type="text" className="form-control form-control--otp js-otp-input" inputMode="numeric" pattern="[0-9]*" autoComplete="one-time-code" required />
                                <input type="text" className="form-control form-control--otp js-otp-input" inputMode="numeric" pattern="[0-9]*" required />
                                <input type="text" className="form-control form-control--otp js-otp-input" inputMode="numeric" pattern="[0-9]*" required />
                                <input type="text" className="form-control form-control--otp js-otp-input" inputMode="numeric" pattern="[0-9]*" required />
                                <input type="text" className="form-control form-control--otp js-otp-input" inputMode="numeric" pattern="[0-9]*" required />
                                <input type="text" className="form-control form-control--otp js-otp-input" inputMode="numeric" pattern="[0-9]*" required />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className='d-flex align-items-center justify-content-center fs-16 fs-12 mb-lg-30 mb-xs-20'>
                    <span>Didn't receive an OTP?&nbsp;&nbsp;</span>
                    {
                        timer > 0 && !showResend ? <span>00:{timer} secs left</span>
                            : <span className='text-brand text-decoration-underline'
                                onClick={resendOTP}>Resend</span>
                    }
                </div>

                <button
                    type='button'
                    className='btn btn-primary btn-brand'
                    onClick={(e) => {
                        e.preventDefault();
                        props.submitForm2Handler(e)
                    }}
                    disabled={!props.form2Details || Object.values((props.form2Details)).filter(Boolean).length != 1}>
                    Next
                </button>
            </form>
        </div>
    );
}

export default Form2;