import Axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';
import './Complain.scss';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import ThankYou from './thankYou';
import moment from 'moment';
import { subDeliveryOptions } from '../../helper/data';

function Complain(props) {    
    const [activeSteps, setActiveStep] = useState({ current: 1, completed: [] }); //TODO current has to be 1
    const [form1Details, setForm1Details] = useState({
        name: '',
        phoneNum: '',
        email: '',
        type: '',
        gender: '',
        age: ''
    });
    const [form2Details, setForm2Details] = useState({
        otp: ''
    });
    const [form3Details, setForm3Details] = useState({
        type: '',
        address: {
            address: '',
            pincode: '',
            city: '',
            state: '',
            landmark: ''
        },
        deliveryAddress: {
            address: '',
            pincode: '',
            city: '',
            state: '',
            landmark: ''
        },
        channel: ''
    });
    const [form1Errors, setForm1Errors] = useState({
        name: false,
        phoneNum: false,
        email: false,
        age: false,
        gender: false
    });
    const [form2Errors, setForm2Errors] = useState({
        otp: false
    });
    const [complainId, setComplanId] = useState('');
    const subOptionRef = useRef("");

    const dispatch = useDispatch();

    const getForm = () => {
        switch (activeSteps.current) {
            case 1:
                return <Form1
                    form1Details={form1Details}
                    setForm1DetailsHandler={setForm1Details}
                    submitForm1Handler={submitForm1}
                    form1Errors={form1Errors}
                    subOptionRef={subOptionRef}
                />;
            case 2:
                return <Form2
                    form2Details={form2Details}
                    setForm2DetailsHandler={setForm2Details}
                    submitForm2Handler={submitForm2}
                    form1Details={form1Details}
                    form2Errors={form2Errors}
                    setActiveStepHandler={setActiveStep}
                />;
            case 3:
                return <Form3
                    form3Details={form3Details}
                    form1Details={form1Details}
                    setForm3DetailsHandler={setForm3Details}
                    setForm1DetailsHandler={setForm1Details}
                    submitForm3Handler={(cb) => submitForm3(cb)}
                    setActiveStepHandler={setActiveStep}
                />;
            case 4:
                return <ThankYou />;
            default:
                return null;
        }
    }
    const submitForm1 = () => {
        if (form1Details && Object.values(form1Details).length == 6) {
            const hasErrors = { name: false, email: false, phoneNum: false, type: false, age: false, gender: false };
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(form1Details.email)) {
                hasErrors.email = true;
            } else hasErrors.email = false;

            if (form1Details.phoneNum.length != 10 || ![6, 7, 8, 9].includes(parseInt(form1Details.phoneNum.toString()[0]))) {
                hasErrors.phoneNum = true;
            } else hasErrors.phoneNum = false;

            if (!form1Details.type) hasErrors.type = true;
            else hasErrors.type = false;

            if(form1Details?.type?.value === 'delivery' && !subOptionRef?.current?.state?.selectValue?.length) {
                // no sub option is selected
                hasErrors.type2 = true;
            } else {
                hasErrors.type2 = false;
            }
            if (!form1Details.age || form1Details.age < 10 || form1Details.age > 90) {
                hasErrors.age = true;
            } else {
                hasErrors.age = false;
            }
            if (!form1Details.gender) {
                hasErrors.gender = true;
            } else {
                hasErrors.gender = false;
            }

            setForm1Errors(hasErrors);

            if (!Object.values(hasErrors).filter(Boolean).length) {
                // TODO call an api to send data and get otp
                dispatch(setIsLoading(true));
                let reqBody = {
                    "name": form1Details.name,
                    "mobile": form1Details.phoneNum, //only mandatory field
                    "email": form1Details.email
                };
                let config = {
                    method: 'POST',
                    url: process.env.REACT_APP_BUILD_API_URL + 'sendComplainLogin',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: reqBody
                };
                Axios(config)
                    .then(res => {
                        if (res.data.code) {
                            setForm3Details(prev => ({...prev, type: subOptionRef?.current?.state?.selectValue[0]}))
                            dispatch(setIsLoading(false));
                            dispatch(setAlert({ status: true, msg: "OTP has been successfully sent to your number.", variant: "success" }));
                            setTimeout(() => {
                                dispatch(setAlert({ status: false, variant: "success", msg: "" }));
                            }, 3000);
                            const newSteps = { current: 2, completed: [1] }
                            setActiveStep(newSteps);
                            document.body.scrollTo({ top: 0 });
                        } else {
                            dispatch(setIsLoading(false));
                            dispatch(setAlert({ status: true, msg: res.data.message || "Something went wrong", variant: "danger" }));
                            setTimeout(() => {
                                dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                            }, 3000);
                        }
                    }).catch(error => {
                        console.log("error",error)
                        dispatch(setIsLoading(false));
                        dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                        setTimeout(() => {
                            dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                        }, 3000);
                    })
            }
        } else {
            dispatch(setAlert({ status: false, variant: "danger", msg: "Please fill in required fields" }));
        }
    }

    const submitForm2 = (event) => {
        event.preventDefault();
        //TODO call API to verify otp
        if (form2Details && form2Details.otp && form2Details.otp.length != 6) {
            setForm2Errors({ otp: true });
        } else if (form2Details && form2Details.otp && form2Details.otp.length == 6) {
            setForm2Errors({ otp: false });
            dispatch(setIsLoading(true));
            let config = {
                method: 'POST',
                url: process.env.REACT_APP_BUILD_API_URL + 'verifyComplainOTP',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "mobile": form1Details.phoneNum,
                    "otp": form2Details.otp
                }
            };
            Axios(config)
                .then(res => {
                    if (res.data.code === 1) {
                        dispatch(setIsLoading(false));
                        setForm1Details(prev => ({...prev, token: res.data.token}))
                        const newSteps = { current: 3, completed: [1, 2] }
                        setActiveStep(newSteps);
                    } else {
                        dispatch(setIsLoading(false));
                        dispatch(setAlert({ status: true, msg: res.data.message, variant: "danger" }));
                        setTimeout(() => {
                            dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                        }, 3000);
                    }
                }).catch(error => {
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                });

        }
    };

    const submitForm3 = (callback) => {
        //api to submit complain data
        dispatch(setIsLoading(true));
        let reqBody = new FormData();
        switch (form1Details.type.value) {
            case 'delivery':
            case 'delivery-1':
            case 'delivery-2':
            case 'delivery-3':
                let type = subDeliveryOptions.find(optn => optn.label === form3Details.type.label)?.value;
                reqBody.append('complaint_type', type);
                reqBody.append('name', form1Details.name);
                reqBody.append('email', form1Details.email);
                reqBody.append('age', form1Details.age);
                reqBody.append('gender', form1Details.gender.value);
                reqBody.append('order_id', form3Details.orderId);
                if(form3Details.channel) {
                    reqBody.append('channel', form3Details.channel?.value);
                }
                if(form3Details.comments) {
                    reqBody.append('issue_description', form3Details.comments);
                }
                // reqBody.append('purchase_date', moment(form3Details.orderDate).format('DD-MM-YYYY'));
                if (form3Details.images && form3Details.images.length) {
                    [...form3Details.images].forEach(element => {
                        reqBody.append('product_images', element);
                    });
                }
                if(form3Details.video) {
                    reqBody.append('product_videos', form3Details.video);
                }
                if(form3Details.invoiceDetails) {
                    reqBody.append('invoice_details', form3Details.invoiceDetails);
                }
                if(form3Details.skus?.length) {
                    // form3Details.skus.forEach(itm => {
                    //     reqBody.append('sku', JSON.stringify(itm));
                    // })
                  reqBody.append('sku', JSON.stringify(form3Details.skus));
                }
                reqBody.append('token', form1Details.token);
                break;
            case 'product_quality':
                reqBody.append('complaint_type', form1Details.type.value);
                reqBody.append('name', form1Details.name);
                reqBody.append('email', form1Details.email);
                reqBody.append('age', form1Details.age);
                reqBody.append('gender', form1Details.gender.value);
                // reqBody.append('order_id', form3Details.orderId);
                // reqBody.append('issue_description', form3Details.comments);
                // reqBody.append('channel', form3Details.channel?.value);
                if(form3Details.category.label.includes('TWS')){
                    reqBody.append('product_category', 'TWS')
                } else reqBody.append('product_category', form3Details.category.label);
                // reqBody.append('product_name', form3Details.product.label);
                reqBody.append('main_problem', form3Details.mainProblem?.value);
                // reqBody.append('sub_problem', form3Details.subProblem?.value);
                // reqBody.append('purchase_date', form3Details.orderDate);
                // reqBody.append('invoice_value', form3Details.invoiceVal);
                // reqBody.append('invoice_number', form3Details.invoice);
                // reqBody.append('invoice_date', form3Details.invoiceDate);
                reqBody.append('invoice_details', form3Details.invoiceDetails);
                reqBody.append('product_videos', form3Details.video);
                reqBody.append('pickup_address', JSON.stringify(form3Details.address));
                reqBody.append('shipping_address', JSON.stringify(form3Details.deliveryAddress));
                if (form3Details.images && form3Details.images.length) {
                    [...form3Details.images].forEach(element => {
                        reqBody.append('product_images', element);
                    });
                }
                reqBody.append('token', form1Details.token);
                break;
            case 'air_bike_installation':
                reqBody.append('complaint_type', form1Details.type.value);
                reqBody.append('name', form1Details.name);
                reqBody.append('email', form1Details.email);
                reqBody.append('age', form1Details.age);
                reqBody.append('gender', form1Details.gender.value);
                // reqBody.append('order_id', form3Details.orderId);
                // reqBody.append('issue_description', form3Details.comments);
                // reqBody.append('channel', form3Details.channel?.value);
                reqBody.append('product_category', form3Details.category.label);
                // reqBody.append('product_name', form3Details.product.label);
                // reqBody.append('invoice_value', form3Details.invoiceVal);
                // reqBody.append('invoice_number', form3Details.invoice);
                // reqBody.append('invoice_date', form3Details.invoiceDate);
                reqBody.append('main_problem', form3Details.mainProblem?.value);
                // reqBody.append('purchase_date', form3Details.orderDate);
                // reqBody.append('sub_problem', form3Details.subProblem?.value);
                reqBody.append('invoice_details', form3Details.invoiceDetails);
                if (form3Details.video) reqBody.append('product_videos', form3Details.video);
                reqBody.append('shipping_address', JSON.stringify(form3Details.deliveryAddress));
                if (form3Details.images && form3Details.images.length) {
                    [...form3Details.images].forEach(element => {
                        reqBody.append('product_images', element);
                    });
                }
                reqBody.append('token', form1Details.token);
                break;
            default:
                break;
        }
        let config = {
            method: 'POST',
            url: process.env.REACT_APP_JAVA_API_URL + 'api/support/registerComplaint',
            data: reqBody
        };
        Axios(config)
            .then(res => {
                if (res.data.code === 1) {
                    setComplanId(res.data.complainId);
                    const newSteps = { current: 4, completed: [1, 2, 3] }
                    setActiveStep(newSteps);
                    if (callback) callback();
                    dispatch(setIsLoading(false));
                    document.body.scrollTo({ top: 0 });
                } else if(res.data.code) {
                    if (callback) callback();
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: res.data.message, variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                } else {
                    if (callback) callback();
                    dispatch(setIsLoading(false));
                    if(res.data.message === "Token is invalid"){
                        dispatch(setAlert({ status: true, msg: "Something went wrong, try to refresh the page.", variant: "danger" }));
                        setTimeout(() => {
                            dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                        }, 3000);
                        return;
                    }
                    dispatch(setAlert({ status: true, msg: res.data.message, variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                }
            }).catch(error => {
                if (callback) callback();
                dispatch(setIsLoading(false));
                dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                setTimeout(() => {
                    dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                }, 3000);
            })
    }

    useEffect(() => {
        document.title = "Register Complaint: beatXP";
    }, [])

    return (
        <div className='container mt-5'>
            <section id="header">
                <h3 className='mb-20'>Register your Complaint</h3>
                <p className='mb-20'>Please complete the form below with all the necessary details.
                    This will help us to better understand your needs and respond to you quickly with a solution.</p>
                <div className='progress-wrapper'>
                    <div className={`${activeSteps.completed.includes(1) ? 'step-completed' : ''} ${activeSteps.current == 1 ? 'active-step' : ''} progress-step `} >
                        <span className='step'>
                            {
                                activeSteps.completed.includes(1) ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                                </svg> : 1
                            }
                        </span>
                        <span>Personal Details</span>
                    </div>
                    <div className='line-bar' style={{ width: '100%', position: 'relative' }}>
                        <div className={`${activeSteps.completed.includes(1) ? 'line step-completed' : ''}`}>
                        </div>
                        <div className={`${activeSteps.completed.includes(2) ? 'line half-step-completed' : 'line'}`}>
                        </div>
                    </div>
                    <div className={`${activeSteps.completed.includes(2) ? 'step-completed' : ''} ${activeSteps.current == 2 ? 'active-step' : ''} progress-step `} >
                        <span className='step'>
                            {
                                activeSteps.completed.includes(2) ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                                </svg> : 2
                            }
                        </span>
                        <span>Verify OTP</span>
                    </div>
                    <div className='line-bar' style={{ width: '100%', position: 'relative' }}>
                        <div className={`${activeSteps.completed.includes(2) ? 'line step-completed' : ''}`}>
                        </div>
                        <div className={`${activeSteps.completed.includes(3) ? 'line half-step-completed' : 'line'}`}>
                        </div>
                    </div>

                    <div className={`${activeSteps.completed.includes(3) ? 'step-completed' : ""} ${activeSteps.current == 3 ? 'active-step' : ''} progress-step `} >
                        <span className='step'>{
                            activeSteps.completed.includes(3) ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                            </svg> : 3
                        }</span>
                        <span>Complaint Info</span>
                    </div>

                    <div className='line-bar' style={{ width: '100%', position: 'relative' }}>
                        <div className={`${activeSteps.completed.includes(3) ? 'line step-completed' : ''}`}>
                        </div>
                        <div className={`${activeSteps.current == 4 ? 'line half-step-completed' : 'line'}`}>
                        </div>
                    </div>

                    <div className={`${activeSteps.current == 4 ? 'step-completed' : ''} progress-step `} >
                        <span className='step'>{
                            activeSteps.current == 4 ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                            </svg> : 4
                        }</span>
                        <span>Register Complaint</span>
                    </div>
                </div>

            </section>
            {getForm()}
        </div>
    );
}

export default Complain;