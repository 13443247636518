import React, { useEffect, useState } from 'react';
import './index.scss';
import { useDispatch } from 'react-redux';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';
import Axios from 'axios';
import Select, { components } from 'react-select';
import Swal from 'sweetalert2';

function Sale(props) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        pincode: '',
        city: '',
        state: '',
        landmark: '',
        product: []
    });
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [formErrors, setFormErrors] = useState();
    const [productList, setProductList] = useState([]);
    const dispatch = useDispatch();

    const setFormDataHandler = (event, key) => {
        let updatedFormData = formData;
        if (key === 'phone') {
            updatedFormData = {
                ...updatedFormData,
                phone: event.target.value.slice(0, 10)
            }
        } else if (key === 'pincode') {
            updatedFormData = {
                ...updatedFormData,
                pincode: event.target.value.slice(0, 6)
            }
        } else if (key === 'product') {
            updatedFormData = {
                ...updatedFormData,
                product: event
            }
            setSelectedProducts(event);
        } else {
            updatedFormData = {
                ...updatedFormData,
                [key]: event.target.value
            };
        }

        setFormData(updatedFormData);
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isFormInvalid = () => {
        let newErrors = {
            name: !Boolean(formData?.name),
            email: !Boolean(formData?.email) || !validateEmail(formData.email),
            phone: !Boolean(formData?.phone) || formData.phone.length != 10,
            pincode: !Boolean(formData?.pincode),
            city: !Boolean(formData?.city),
            state: !Boolean(formData?.state),
            address: !Boolean(formData?.address),
            landmark: !Boolean(formData?.landmark),
            product: !Boolean(formData?.product?.length),
        };
        setFormErrors(newErrors);
        return Object.values(newErrors).filter(Boolean)?.length;
    }

    const submitHandler = () => {
        setIsFormSubmitted(true);
        dispatch(setIsLoading(true));
        if (isFormInvalid()) {
            dispatch(setIsLoading(false));
            dispatch(setAlert({ status: true, variant: "danger", msg: "Please fill required fields with correct data." }));
            setTimeout(() => {
                dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
            }, 2000);
            return false;
        } else {
            let reqBody = {
                "phone": formData.phone,
                "email": formData.email,
                "name": formData.name,
                "address": {
                    "landmark": formData.landmark,
                    "city": formData.city,
                    "state": formData.state,
                    "pincode": formData.pincode,
                    "address": formData.address
                },
                "skuList": selectedProducts.map(i => ({ "sku": i.clientSku, "quantity": parseInt(i.quantity) }))
            };
            Axios.post(`${process.env.REACT_APP_JAVA_API_URL}javadashboard/independence/saveOrder`, reqBody, {
                headers: {
                    'token': 'BXP_INDEPENDENCE_SALE_AUG_15'
                }
            }).then(res => {
                if (res.data.code == 1) {
                    dispatch(setIsLoading(false));
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        address: '',
                        pincode: '',
                        city: '',
                        state: '',
                        landmark: '',
                        product: []
                    });
                    setSelectedProducts([]);
                    Swal.fire({
                        title: 'Successful!',
                        text: 'Thank you for placing your order.',
                        icon: 'success',
                        showConfirmButton: false,
                        backdrop: true,
                        allowOutsideClick: false
                    });
                } else {
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ variant: 'danger', msg: 'Something went wrong', status: true }));
                    setTimeout(() => {
                        dispatch(setAlert({ variant: 'danger', msg: '', status: false }));
                    }, 2000);
                }
            }).catch(err => {
                console.log(err);
                dispatch(setIsLoading(false));
                dispatch(setAlert({ variant: 'danger', msg: 'Something went wrong', status: true }));
                setTimeout(() => {
                    dispatch(setAlert({ variant: 'danger', msg: '', status: false }));
                }, 2000);

            })
        }
    }

    const setSkuQuantity = (event, sku) => {
        const qty = event.target.value;
        let updatedList = formData.product;
        let index = updatedList.findIndex((x) => x.clientSku == sku);
        if (index !== -1) {
            updatedList[index] = {
                ...updatedList[index],
                quantity: qty
            };

            setFormData(prev => ({
                ...prev,
                product: updatedList
            }))
        }

    }

    const deleteProduct = (item) => {
        let updatedList = [...formData.product];
        let index = updatedList.findIndex((x) => x.clientSku == item.clientSku);
        if (index !== -1) {
            updatedList.splice(index, 1);
            setFormData(prev => ({
                ...prev,
                product: updatedList
            }));
        }
    }

    const DropdownIndicator = props => {
        if (props.isDisabled) return null;
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.8443 0.303668C1.4392 -0.101223 0.7828 -0.101223 0.3776 0.303668C-0.0272007 0.708764 -0.0272008 1.36517 0.3776 1.77031L6.30367 7.69633C6.70876 8.10122 7.36517 8.10122 7.77031 7.69633L13.6963 1.77031C14.1012 1.36521 14.1012 0.708807 13.6963 0.303668C13.2912 -0.101222 12.6348 -0.101222 12.2297 0.303668L7.0341 5.49926L1.8443 0.303668Z" fill="#FF8300" />
                    </svg>
                </components.DropdownIndicator>
            )
        );
    };

    useEffect(() => {
        try {
            dispatch(setIsLoading(true));
            const reqBody = { "currentPage": 0, "perPage": 1200 };
            Axios.post(`${process.env.REACT_APP_JAVA_API_URL}javacatalog/api/inventoryMaster/fetchAllProducts`, reqBody).then(res => {
                if (res.data.productList?.length) {
                    let updatedList = res.data.productList.map(item => ({ ...item, label: `${item.inventoryProductName} - [${item.clientSku}]`, value: item.clientSku }));
                    setProductList(updatedList);
                    dispatch(setIsLoading(false));
                } else {
                    dispatch(setIsLoading(false));
                }
            })
        } catch (error) {
            dispatch(setIsLoading(false));
        }
    }, []);

    useEffect(() => {
        let timer = setTimeout(() => {
            try {
                if (formData?.pincode && formData?.pincode.length === 6) {
                    dispatch(setIsLoading(true));
                    Axios.post("https://pristinecare.net/v2-api/guestShipping/getPinDetails", {
                        'pincode': parseInt(formData.pincode)
                    }).then(res => {
                        if (res.data.code == '1' && res.data.pinDetails?.deliveryStatus != "true") {
                            dispatch(setIsLoading(false));
                            setFormErrors(prev => ({
                                ...prev, pincode: true
                            }));
                            dispatch(setAlert({ variant: 'danger', msg: 'This pincode is unservicable, enter another one.', status: true }));
                            setTimeout(() => {
                                dispatch(setAlert({ variant: 'danger', msg: '', status: false }));
                            }, 2000);
                        } else if(res.data.code == '1' && res.data.pinDetails){
                            dispatch(setIsLoading(false));
                            setFormData(prev => ({
                                ...prev,
                                city: res.data.pinDetails.city,
                                state: res.data.pinDetails.state
                            }))
                        } else {
                            dispatch(setIsLoading(false));
                        }
                    })
                }
            } catch (error) {
                console.log('err', error);
                dispatch(setIsLoading(false));
            }
        }, 1500);

        return () => {
            clearTimeout(timer)
        }
    }, [formData.pincode])


    return (
        <div className='margin-top'>
            <form onSubmit={(e) => {
                e.preventDefault();
                submitHandler();
            }}>
                <div className="did-floating-label-content">
                    <label className="did-floating-label">Name<span className='text-danger'>*</span></label>
                    <input className={`did-floating-input ${formErrors?.name ? 'border-error' : ''}`} type="text" placeholder="Enter your name"
                        onChange={(e) => setFormDataHandler(e, 'name')}
                        value={formData?.name}
                        maxLength={20}
                    />
                </div>
                <div className="did-floating-label-content">
                    <label className="did-floating-label">Email<span className='text-danger'>*</span></label>
                    <input className={`did-floating-input ${formErrors?.email ? 'border-error' : ''}`}
                        type="email"
                        placeholder="Enter your email-id"
                        onChange={(e) => setFormDataHandler(e, 'email')}
                        value={formData?.email}
                    />
                </div>
                <div className="did-floating-label-content">
                    <label className="did-floating-label">Phone Number<span className='text-danger'>*</span></label>
                    <input className={`did-floating-input ${formErrors?.phone ? 'border-error' : ''}`}
                        type="number"
                        placeholder="Enter your phone number"
                        onChange={(e) => setFormDataHandler(e, 'phone')}
                        value={formData?.phone}
                    />
                    {isFormSubmitted && formData?.phone && formData?.phone?.length != 10 ? <span className='text-danger' style={{fontSize: '12px'}}>Please enter 10 digit number</span> : ''}
                </div>
                <div className="did-floating-label-content mb-0">
                    <label className="did-floating-label">Delivery Address<span className='text-danger'>*</span></label>
                    <div>
                        <div className='w-100'>
                            <div className='address-wrapper'>
                                <div className="did-floating-label-content w-100">
                                    <label className="did-floating-label">Address<span className='text-danger'>*</span></label>
                                    <input className={`did-floating-input ${formErrors?.address ? 'border-error' : ''}`} type="text" placeholder="Enter your complete address"
                                        name="address"
                                        maxLength={180}
                                        onChange={(e) => setFormDataHandler(e, 'address')}
                                        value={formData?.address}
                                    />
                                </div>
                                <div className="did-floating-label-content" id='pincode'>
                                    <label className="did-floating-label">Pincode<span className='text-danger'>*</span></label>
                                    <input className={`did-floating-input ${formErrors?.pincode ? 'border-error' : ''} `}
                                        type="number"
                                        placeholder="Enter your pincode"
                                        name="pincode"
                                        onChange={(e) => setFormDataHandler(e, 'pincode')}
                                        value={formData?.pincode}
                                    />
                                </div>
                                <div className="did-floating-label-content" id='city'>
                                    <label className="did-floating-label">City<span className='text-danger'>*</span></label>
                                    <input className={`did-floating-input ${formErrors?.city ? 'border-error' : ''}`} type="text" placeholder="Enter your city"
                                        name="city"
                                        maxLength={20}
                                        onChange={(e) => setFormDataHandler(e, 'city')}
                                        value={formData?.city}
                                    />
                                </div>
                                <div className="did-floating-label-content" id="state">
                                    <label className="did-floating-label">State<span className='text-danger'>*</span></label>
                                    <input className={`did-floating-input ${formErrors?.state ? 'border-error' : ''}`} type="text" placeholder="Enter your state"
                                        name="state"
                                        maxLength={20}
                                        onChange={(e) => setFormDataHandler(e, 'state')}
                                        value={formData?.state}
                                    />
                                </div>
                                <div className="did-floating-label-content" id='landmark'>
                                    <label className="did-floating-label">Landmark<span className='text-danger'>*</span></label>
                                    <input className={`did-floating-input ${formErrors?.landmark ? 'border-error' : ''} `} type="text" placeholder="Enter landmark"
                                        name="landmark"
                                        onChange={(e) => setFormDataHandler(e, 'landmark')}
                                        value={formData?.landmark}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {productList?.length ? <div className="did-floating-label-content w-100">
                    <label className="did-floating-label">Product<span className='text-danger'>*</span></label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        isMulti
                        name="products"
                        components={{ DropdownIndicator }}
                        placeholder="Select product"
                        options={productList}
                        classNames={{
                            control: (state) =>
                                formErrors?.product ? 'border-error' : '',
                        }}
                        styles={{
                            container: (baseStyles) => ({
                                ...baseStyles,
                                width: '100%'
                            }),
                            menu: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 2,
                            }),
                            menuList: (baseStyles, state) => ({
                                ...baseStyles,
                                textAlign: 'left'
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                textAlign: 'left'
                            }),
                            control: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: '16px',
                                background: '#fff',
                                color: '#323840',
                                border: '1px solid var(--input-border-color)',
                                borderColor: 'var(--input-border-color)',
                                borderRadius: '10px',
                                boxSizing: 'border-box',
                                minHeight: '50px',
                                textAlign: 'left',
                                '&:hover': {
                                    borderColor: 'var(--input-border-color)'
                                }
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: 'var(--border-color)'
                            }),
                            indicatorSeparator: (baseStyles) => ({
                                display: 'none'
                            }),
                            dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                '& svg': {
                                    fill: '#000'
                                }
                            })
                        }}
                        value={formData?.product}
                        onChange={(value) => setFormDataHandler(value, 'product')}
                    />
                    {
                        formErrors?.product ? <span className='error-msg'>Please select product</span> : null
                    }
                </div> : null}
                {
                    formData?.product?.length ? <div className='d-flex flex-wrap'>
                        {
                            formData?.product.map(item => (
                                <div key={item.value} className='card p-3 mb-3 w-100'>
                                    <div className='d-flex'
                                        style={{
                                            gap: '20px'
                                        }}>
                                        {/* <img src={item.imageUrl} width={50} height={50} alt='thumb' /> */}
                                        <div className='text-left'>
                                            <span>{item.inventoryProductName}</span><br />
                                            <span className='text-secondary'><b>Category: </b>{item.categoryDetails.categoryName}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex mt-3'>
                                        <input name='quantity' type='number'
                                            placeholder='Enter Quantity'
                                            min={1}
                                            className={`did-floating-input ${isFormSubmitted && !item.quantity ? 'border border-danger' : ''}`}
                                            onChange={(e) => {
                                                setSkuQuantity(e, item.clientSku);
                                            }} />
                                        {/* <button
                                            type="button"
                                            className='align-items-center btn btn-danger btn-sm btn-xs d-flex ml-3 w-auto'
                                            onClick={() => deleteProduct(item)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={20} height={20}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                        </button> */}
                                    </div>
                                </div>
                            ))
                        }
                    </div> : ""
                }
                <button className='btn btn-primary'>Submit</button>
            </form>
        </div>
    );
}

export default Sale;