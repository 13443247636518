import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import LoginComponent from './pages/login';
import NavBar from './components/navbar';
import { Redirect } from 'react-router-dom';
import HomeComponent from './pages/home';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { setAlert, setCategory, setIsMobile } from './store/ui/uiSlice';
import { Alert } from 'react-bootstrap';
import ComplaintWrapper from './pages/complain/ComplaintWrapper';
import RequestDocs from './pages/requestDocs/RequestDocs';
import RequestDocsForWarehouse from './pages/requestDocsForWarehouse/RequestDocsForWarehouse';
import ComplaintTrack from './pages/complaintTrack/ComplaintTrack';
import Footer from './components/footer';
import { categoryList } from './helper/data';
import Sale from './pages/sale/Sale';

function getCategoryPriority(id) {
  let catArr = [46, 37, 24, 19, 23, 42]
  id = Number(id)
  let value = catArr.includes(id) ? catArr.findIndex((x) => x === id) : 100
  return value
}

function App() {
  const [show, setvalue] = useState(true);
  const ui = useSelector(state => state.ui);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname.includes("warehouse") || window.location.pathname.includes("sale")) {
      setvalue(false);
    }
    else {
      setvalue(true);
    }
    function handleResize() {
      dispatch(setIsMobile(window.innerWidth));
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  let category = categoryList.filter((x) => {
    let activeCatListPriority = getCategoryPriority(x.id)
    if (activeCatListPriority != 100) {
      return {
        ...x,
        priority: activeCatListPriority
      }
    }
  })
  category = category.sort((a, b) => Number(b.priority) - Number(a.priority)).reverse()


  return (
    <div className="App">
      {
        ui.isLoading ?
          <div
            style={{
              backgroundColor: "rgb(255 255 255 / 46%)",
              top: 0,
              zIndex: 30,
              position: "fixed",
              width: "100%",
              height: "100%"
            }}>
            <Spinner animation="border" role="status"
              style={{
                top: 'calc(50% - 50px)',
                position: 'absolute',
                left: 'calc(50% - 50px)',
                width: '100px',
                height: '100px',
              }} />
          </div> : ''
      }
      <Alert className="track-alert"
        show={ui.showAlert}
        variant={ui.variant}
        onClose={() => dispatch(setAlert({ status: false }))} >
        {ui.alertMessage}
      </Alert>
      {show ? (<NavBar category={category} />) : <NavBar />}
      <Router>
        <Switch>
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/complain" : "/complain"} exact component={ComplaintWrapper} />
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/complain/request" : "/complain/request"} component={RequestDocs} />
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/complain/warehouse" : "/complain/warehouse"} component={RequestDocsForWarehouse} />
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/track-order" : "/track-order"} exact component={LoginComponent} />
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/track-order:order:phone" : "/track-order:order:phone"} component={HomeComponent} />
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/track-complaint" : "/track-complaint"} exact component={LoginComponent} />
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/track-complaint:id:phone" : "/track-complaint:id:phone"} component={ComplaintTrack} />
          <Route path={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/sale" : "/sale"} component={Sale} />
          <Redirect to={process.env.REACT_APP_NODE_ENV == 'development' ? "/support-dashboard/track-order" : "/track-order"} />
        </Switch>
      </Router>
      {show ? (<Footer category={category} />) : ""}
    </div>
  );
}

export default App;
